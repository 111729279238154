import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTerminal } from "@fortawesome/free-solid-svg-icons"

const SkillGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 480px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Skill = styled.p`
  font-family: "Quicksand";
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 2px;

  @media (min-width: 480px) {
    font-size: 18px;
  }
`

const Skills = () => {
  return (
    <SkillGrid>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Javascript
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Typescript
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> SQL
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> NodeJS
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Python
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> AWS
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Postgres
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Ruby on Rails
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> React
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Django
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> GraphQL
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> Serverless
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> HTML
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> CSS/SCSS
      </Skill>
      <Skill>
        <FontAwesomeIcon icon={faTerminal} size="xs" /> JSON
      </Skill>
    </SkillGrid>
  )
}

export default Skills

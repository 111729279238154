import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ModalWrapper, {
  ImageContainer,
  TwoColumn,
  Stack,
  SubHead,
  Info,
  ImageSection,
} from "./modalWrapper"

const BookishModal2 = ({ toggleBookishModal2 }) => {
    const data = useStaticQuery(graphql`
    query {
      b1: file(relativePath: { eq: "bookish2/bookish2-frame.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      b2: file(relativePath: { eq: "bookish2/bookish2-detail1.png"  }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      b3: file(relativePath: { eq: "bookish2/bookish2--detail2.png"  }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      b4: file(relativePath: { eq: "bookish2/bookish2-detail3.png"  }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      b5: file(relativePath: { eq: "bookish2/bookish2-detail4.png"  }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ModalWrapper
      toggle={toggleBookishModal2}
      title="Bookish 2.0"
      description="Bookish 2.0 is a Python/Django web application, built to have the same functionality as Amazon's Goodreads. With the same data models and pages as Bookish, I wanted to create a faster experience for the user and chose Django to achieve this."
    >
      <>
        <TwoColumn>
          <ImageContainer>
            <Img fluid={data.b1.childImageSharp.fluid} />
            <small>Bookish 2.0</small>
          </ImageContainer>
          <Stack>
            <h4>Stack</h4>
            <ul>
              <li>Python</li>
              <li>Django</li>
              <li>Django Filter</li>
              <li>SQLite3</li>
              <li>New York Times Books API</li>
              <li>Google Books API</li>
              <li>Bootstrap CSS</li>
            </ul>
          </Stack>
        </TwoColumn>
        <SubHead>Test</SubHead>
        <Info>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Info>
        <ImageSection>
          <ImageContainer className="big">
            <Img fluid={data.b5.childImageSharp.fluid} />
            <small>Book info page and CTAs</small>
          </ImageContainer>
          <TwoColumn>
            <ImageContainer>
              <Img fluid={data.b4.childImageSharp.fluid} />
              <small>User's bookshelf / profile view</small>
            </ImageContainer>
            <ImageContainer>
              <Img fluid={data.b2.childImageSharp.fluid} />
              <small>Bookish 2.0 Register page</small>
            </ImageContainer>
          </TwoColumn>
          <ImageContainer className="big">
            <Img fluid={data.b3.childImageSharp.fluid} />
            <small>Bookish 2.0 Search page</small>
          </ImageContainer>
        </ImageSection>
      </>
    </ModalWrapper>
  )
}
export default BookishModal2

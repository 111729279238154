import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import BookishModal from "./bookishModal"
import BookishModal2 from "./bookish2Modal"
import FootballModal from "./footballModal"
import { useMedia } from "react-media"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Slide = styled.div`
  text-align: center;
  cursor: pointer;

  :focus {
    outline: none;
  }

  p {
    font-family: "Quicksand";
    font-weight: 300;
    max-width: 400px;
    margin: auto;
    margin-top: 12px;
  }

`

const ProjectTitle = styled.h3`
  font-family: "Montserrat";
  font-weight: 700;
  letter-spacing: -2px;
`

const ReadMore = styled.span`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  :hover {
    background-color: #fff704;
  }
`

const ModalBackground = styled.div`
  position: fixed;
  padding-top: 50px;
  padding-bottom: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow-y: scroll;
`

export const imageShape = graphql`
  fragment imageShape on File {
    childImageSharp {
      fluid(maxHeight: 300, maxWidth: 500) {
          ...GatsbyImageSharpFluid
      }
    }
  }
`

const Projects = () => {
  const isSmallScreen = useMedia({ query: "(max-width: 700px)" })
  const [bookishModalOpen, setBookishModalOpen] = useState(false)
  const [bookishModal2Open, setBookishModal2Open] = useState(false)
  const [footballModalOpen, setFootballModalOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      bookish: file(relativePath: { eq: "project-bookish.png" }) {
        ...imageShape
      }
      bookish2: file(relativePath: { eq: "project-bookish2.png" }) {
        ...imageShape
      }
      football: file(relativePath: { eq: "project-ff.png" }) {
        ...imageShape
      }
      ndb: file(relativePath: { eq: "project-ndb.png" }) {
        ...imageShape
      }
    }
  `)

  const toggleBookishModal = () => {
    setBookishModalOpen(!bookishModalOpen)
  }

  const toggleFootballModal = () => {
    setFootballModalOpen(!footballModalOpen)
  }

  const toggleBookishModal2 = () => {
    setBookishModal2Open(!bookishModal2Open)
  }

  const openNDB = () => {
    window.open('https://ndb-open-letter.com/', '_blank');
  }

  let settings = {
    dots: true,
    infinite: false,
    speed: 700,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    // autoplay: true,
  }

  if (isSmallScreen) {
    settings.slidesToShow = 1
    settings.slidesToScroll = 1
  }

  return (
    <>
      <div>
        <Slider {...settings}>
          <Slide onClick={toggleBookishModal}>
            <ProjectTitle>Bookish</ProjectTitle>
            <Img fluid={data.bookish.childImageSharp.fluid} />
            <p>
              A book discovery app for users to save, review, and recommend
              books with friends
            </p>
            <ReadMore onClick={toggleBookishModal}>
              Read more <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </ReadMore>
          </Slide>
          <Slide onClick={toggleFootballModal}>
            <ProjectTitle>FantasyFootball</ProjectTitle>
            <Img fluid={data.football.childImageSharp.fluid} />
            <p>
              An app for my fantasy football league to view their rankings, stats
              upcoming matchup, and weekly recaps.
            </p>
            <ReadMore onClick={toggleFootballModal}>
              Read more <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </ReadMore>
          </Slide>
          <Slide onClick={toggleBookishModal2}>
            <ProjectTitle>Bookish 2.0</ProjectTitle>
            <Img fluid={data.bookish2.childImageSharp.fluid} />
            <p>
              A book discovery app for users to save, review, and recommend
              books with friends (using Django)
            </p>
            <ReadMore onClick={toggleBookishModal2}>
              Read more <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </ReadMore>
          </Slide>
          <Slide onClick={openNDB}>
            <ProjectTitle>Open Letter and Petition to NDB</ProjectTitle>
            <Img fluid={data.ndb.childImageSharp.fluid} />
            <p>
              A website for community members to view the open letter and it's co-signers, sign it, and sign up for email updates.
            </p>
            <ReadMore onClick={openNDB}>
              View site <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </ReadMore>
          </Slide>
        </Slider>
      </div>
      {bookishModalOpen && (
        <>
          <ModalBackground onClick={e=>setBookishModalOpen(false)}>
            <BookishModal toggleBookishModal={toggleBookishModal} />
          </ModalBackground>
        </>
      )}
      {footballModalOpen && (
        <ModalBackground onClick={e=>setFootballModalOpen(false)}>
          <FootballModal toggleFootballModal={toggleFootballModal} />
        </ModalBackground>
      )}
      {bookishModal2Open && (
         <ModalBackground onClick={e=>setBookishModal2Open(false)}>
         <BookishModal2 toggleBookishModal2={toggleBookishModal2} />
        </ModalBackground>
      )}
    </>
  )
}
export default Projects
import React, { useState } from "react"
import styled from "styled-components"

const Text = styled.p`
  font-family: "Quicksand";
  font-weight: 300;
`

const AboutMe = () => {
  return (
    <>
      <Text>
        Hi! I'm Maddy. I am a full stack web developer with a passion for design
        and staying up to date with the industry’s latest trends and
        technologies. With experience in avaScript, React, Python, AWS, Ruby,
        and SQL and a background in communications, I bring strong skills in
        project management, communications, branding and working in fast-paced
        environments that help high-growth companies advance their goals.{" "}
      </Text>
      <Text>
        Prior to my career in software development, I worked in Marketing and
        Public Relations for over five years. I managed client accounts,
        campaigns, and account teams while delivering integral KPI's for my
        agencies and clients.
      </Text>
      <Text>
        I was born and raised in the Bay Area, CA and currently live in New York
        City's West Village. I'm an avid sports fan and enjoy coding, writing,
        cooking, and going on hikes with my dog.
      </Text>
    </>
  )
}

export default AboutMe

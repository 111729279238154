import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ModalWrapper, {
  ImageContainer,
  TwoColumn,
  Stack,
  SubHead,
  Info,
  ImageSection,
} from "./modalWrapper"

const MobileImageContainer = styled(ImageContainer)`
    .gatsby-image-wrapper {
        width: 400px;
    }
`

const FootballModal = ({ toggleFootballModal }) => {
  const data = useStaticQuery(graphql`
    query {
      football1: file(relativePath: { eq: "football/ff-detail1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      football2: file(relativePath: { eq: "football/ff-detail2.png"  }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      football3: file(relativePath: { eq: "football/ff-detail3.png"  }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      football4: file(relativePath: { eq: "football/ff-detail4.png"  }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ModalWrapper
      toggle={toggleFootballModal}
      title="FantasyFootball"
      description="FantasyFootball is a Gatsby/React application for my fantasy football league to view their standings, power rankings, season (and previous season) statistics, and a blog of weekly recaps and announcements from the commissioner. This application is also optimized for mobile for league members to view their team's information on-the-go. "
    >
      <>
        <TwoColumn>
          <ImageContainer>
            <Img fluid={data.football1.childImageSharp.fluid} />
            <small>FantasyFootball</small>
          </ImageContainer>
          <Stack>
            <h4>Stack</h4>
            <ul>
              <li>React</li>
              <li>Gatsby</li>
              <li>Sheety API</li>
              <li>ChartJS</li>
              <li>Netlify</li>
              <li>Netlify CMS</li>
            </ul>
          </Stack>
        </TwoColumn>
        <SubHead>Test</SubHead>
        <Info>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Info>
        <ImageSection>
          <TwoColumn>
            <MobileImageContainer className="big">
            <Img fluid={data.football4.childImageSharp.fluid} />
                <small>Recap blog</small>
            </MobileImageContainer>
            <div>
                <ImageContainer>
                <Img fluid={data.football2.childImageSharp.fluid} />
                <small>ChartJS stat graph for points scored each week</small>
                </ImageContainer>
                <ImageContainer>
                <Img fluid={data.football3.childImageSharp.fluid} />
                <small>Blog landing page</small>
                </ImageContainer>
            </div>
          </TwoColumn>
        </ImageSection>
      </>
    </ModalWrapper>
  )
}
export default FootballModal

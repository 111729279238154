import React from "react"
import styled, { keyframes } from "styled-components"
import { faTimes, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const modalOpenAnimation = keyframes`
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

const Wrapper = styled.div`
  position: relative;
  background-color: white;
  padding: 35px;
  margin: auto;
  width: 85%;
  border-radius: 8px;
  /* overflow-y: scroll; */

  animation-name: ${modalOpenAnimation};
  animation-duration: 0.9s;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: black !important;
`

const slideInLeft = keyframes`
   from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

const ModalContent = styled.div`
  svg {
    animation: ${slideInLeft};
    animation-duration: 0.8s;
    animation-delay: 0.2s;
  }
`

const ModalHeader = styled.h2`
  font-size: 50px;
  font-family: "Montserrat";
  font-weight: 800;
  letter-spacing: -3px;
  margin-top: 28px !important;

  animation: ${slideInLeft};
  animation-duration: 0.8s;
  animation-delay: 0.1s;

  &:after {
    background: none repeat scroll 0 0 black;
    bottom: -10px;
    content: "";
    display: block;
    height: 5px;
    position: relative;
    width: 100px;
  }
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

const Description = styled.p`
  font-family: "Quicksand";
  font-weight: 400;
  animation: ${fadeInDown};
  animation-duration: 0.8s;
  animation-delay: 0.2s;
  font-size: 14px;

  @media (min-width: 600px) {
    font-size: 18px;
  }
`

const Section = styled.section`
  .gatsby-image-wrapper {
    box-shadow: 2px 2px 12px 0 #000000;
    border-radius: 4px;
  }
`

// INDIVIDUAL MODAL CONTENT STYLES

export const ImageContainer = styled.div`
  max-width: 550px;

  small {
    text-align: center;
    font-family: "Quicksand";
    font-weight: 300;
  }
`

export const TwoColumn = styled.div`
  display: grid;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Stack = styled.div`
  @media (min-width: 600px) {
    margin-left: 30px;
  }

  text-align: center;

  h4 {
    font-family: "Montserrat";
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;

    li {
      font-family: "Quicksand";
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
`

export const SubHead = styled.h2`
  font-family: "Montserrat";
  font-weight: 800;
`

export const Info = styled.p`
  font-family: "Quicksand";
  font-weight: 400;
`

export const ImageSection = styled.div`
  ${ImageContainer} {
    margin: 10px;

    &.big {
      margin: auto;
      margin-bottom: 20px;
    }
  }
`

const ModalWrapper = ({ toggle, title, description, children }) => {
  return (
    <Wrapper>
      <FontAwesomeIconStyled icon={faTimes} onClick={toggle} />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <FontAwesomeIcon icon={faAngleDoubleRight} size="6x" pull="left" />
        <Description>{description}</Description>
        <Section>{children}</Section>
      </ModalContent>
    </Wrapper>
  )
}
export default ModalWrapper

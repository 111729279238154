import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutMe from "../components/aboutMe"
import Projects from "../components/projects"
import Skills from "../components/skills"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const TitleHeader = styled.h1`
  font-size: 60px;
  font-family: "Montserrat";
  font-weight: 900;

  span {
    background-color: #fff704;
  }
`

const Header = styled.h2`
  font-size: 50px;
  font-family: "Montserrat";
  font-weight: 800;
  letter-spacing: -4px;

  &:after {
    background: none repeat scroll 0 0 black;
    bottom: -10px;
    content: "";
    display: block;
    height: 5px;
    position: relative;
    width: 100px;
  }

  a {
    box-shadow: none;
    color: black;
  }
`

const Header2 = styled.h2`
  font-family: "Montserrat";
  font-weight: 600;

  a {
    font-weight: 600;
    box-shadow: none;
    color: black;

    &:hover {
      background-color: #fff704;
      svg {
        -webkit-transform: scale(1.3) rotate(2deg);
        transform: scale(1.3) rotate(2deg);
      }
    }

    svg {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
`

const ContactSection = styled.p`
  a {
    font-weight: 500;
    box-shadow: none;
    color: black;
  }
  font-family: "Quicksand";
  font-weight: 300;
`

const RecentBlogHeader = styled.h4`
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 24px;

  a {
    font-weight: 500;
    box-shadow: none;
    color: black;

    &:hover {
      background-color: #fff704;
    }
  }
`

const BlogLink = styled.p`
  font-family: "Quicksand";
  font-weight: 300;
  font-size: 18px;
  text-decoration: none;

  a {
    color: black;
    box-shadow: none;

    &:hover {
      background-color: #fff704;
      svg {
        -webkit-transform: scale(1.3) rotate(2deg);
        transform: scale(1.3) rotate(2deg);
      }
    }

    svg {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
`

const Index = ({ data, location }) => {
  const recentPost = data.allMarkdownRemark.edges[0].node
  return (
    <Layout location={location} title={"Maddy Tabing"}>
      <SEO title="Home" />
      <TitleHeader>
        Hi, I'm Maddy Tabing. <span>Software Engineer</span>, Ex-PR Pro.
      </TitleHeader>

      <Header>About Me</Header>
      <AboutMe />

      <Header>Skills</Header>
      <Skills />

      <Header>What I'm Working On</Header>
      <Projects />

      <Header>
        <Link to="/blog">Blog</Link>
      </Header>
      <Header2>Most recent post:</Header2>
      <RecentBlogHeader>
        <Link to={recentPost.fields.slug}>
          {recentPost.frontmatter.title || recentPost.fields.slug}
        </Link>
      </RecentBlogHeader>
      <BlogLink>{recentPost.excerpt}</BlogLink>
      <BlogLink>
        <Link to={recentPost.fields.slug}>
          Read More <FontAwesomeIcon icon={faChevronRight} size="xs" />
        </Link>
      </BlogLink>
      <Header2>
        <Link to="/blog">
          View all posts <FontAwesomeIcon icon={faChevronRight} size="xs" />
        </Link>
      </Header2>

      <Header>Contact</Header>
      <ContactSection>
        Let's connect! Email me at{" "}
        <a href="mailto:madtab7@gmail.com" target="_blank" rel="noreferrer">
          madtab7@gmail.com
        </a>
      </ContactSection>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`

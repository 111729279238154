import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ModalWrapper, {
  ImageContainer,
  TwoColumn,
  Stack,
  SubHead,
  Info,
  ImageSection,
} from "./modalWrapper"

const BookishModal = ({ toggleBookishModal }) => {
  const data = useStaticQuery(graphql`
    query {
      bookish1: file(relativePath: { eq: "bookish/bookish-detail2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookish2: file(relativePath: { eq: "bookish/bookish-detail4.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookish3: file(relativePath: { eq: "bookish/bookish-detail3.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookish4: file(relativePath: { eq: "bookish/bookish-frame.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookish5: file(relativePath: { eq: "bookish/bookish-detail1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ModalWrapper
      toggle={toggleBookishModal}
      title="Bookish"
      description="Bookish is a React application and Ruby on Rails backend, built to have the same functionality as Amazon's Goodreads. 
      As an avid reader, I wanted to build a cleaner, more modern app for other readers to save their favorite books, books they want to read, and books they have read, as well as review and share these books."
    >
      <>
        <TwoColumn>
          <ImageContainer>
            <Img fluid={data.bookish1.childImageSharp.fluid} />
            <small>Bookish</small>
          </ImageContainer>
          <Stack>
            <h4>Stack</h4>
            <ul>
              <li>React</li>
              <li>Redux</li>
              <li>Rails</li>
              <li>ActiveRecord Serializer</li>
              <li>JSON Web Token</li>
              <li>Semantic-React</li>
              <li>Google Books API</li>
              <li>New York Times Best Sellers' API</li>
            </ul>
          </Stack>
        </TwoColumn>
        <SubHead>Test</SubHead>
        <Info>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Info>
        <ImageSection>
          <ImageContainer className="big">
            <Img fluid={data.bookish4.childImageSharp.fluid} />
            <small>User's bookshelf's review section</small>
          </ImageContainer>
          <TwoColumn>
            <ImageContainer>
              <Img fluid={data.bookish2.childImageSharp.fluid} />
              <small>Search results</small>
            </ImageContainer>
            <ImageContainer>
              <Img fluid={data.bookish3.childImageSharp.fluid} />
              <small>Book info</small>
            </ImageContainer>
          </TwoColumn>
          <ImageContainer className="big">
            <Img fluid={data.bookish5.childImageSharp.fluid} />
            <small>User's bookshelf's "Want To Read" section</small>
          </ImageContainer>
        </ImageSection>
      </>
    </ModalWrapper>
  )
}
export default BookishModal
